import { Component, OnInit } from '@angular/core';
import supportedLanguagesContent from "../../assets/languages.json";
import lifeWiseAssuranceFooterLocalContent from '../../assets/lifewise-assurance-footer-content.json';
import lifeWiseWashingtonFooterLocalContent from '../../assets/lifewise-washington-footer-content.json';
import lifeWiseIndividualFooterLocalContent from '../../assets/lifewise-individual-footer-content.json';
import premerafooterLocalContent from '../../assets/premera-footer-content.json';
import premerahmofooterLocalContent from '../../assets/premera-hmo-footer-content.json';
import premeraMedicareAdvantageLocalContent from '../../assets/premera-medicareadvantage-footer-content.json';
import { FooterContent, SupportedLanguage } from '../shared/models';
import { Constants } from "../shared/constants";
import { ListeningPostSettings } from '../listening-post-settings';

@Component({
  selector: 'pl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  lob: string;
  copyrightText: string;
  supportedLanguages: SupportedLanguage[];
  footerContent: FooterContent;

  ngOnInit() {
    this.footerContent = this.getFooterContent();
    this.supportedLanguages = this.setSupportedLanguages();
    this.lob = ListeningPostSettings.lineOfBusiness;
    this.copyrightText = `&copy; ${new Date().getFullYear()}<br>${this.footerContent?.legalCopyright}`;
  }

  private setSupportedLanguages(): SupportedLanguage[] {
    return supportedLanguagesContent as SupportedLanguage[];
  }

  private getFooterContent(): FooterContent {
    if (ListeningPostSettings.brandingType === Constants.brandTypePremeraHMO)
      return premerahmofooterLocalContent as FooterContent;

    if (ListeningPostSettings.brandingType === Constants.brandTypeLifeWiseAssuranceCompany)
      return lifeWiseAssuranceFooterLocalContent as FooterContent;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostIndividual
      || (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProvider && ListeningPostSettings.brandingType === Constants.brandTypeLifeWiseOfWashington)
      || (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostMobile && ListeningPostSettings.brandingType === Constants.brandTypeLifeWiseOfWashington))
      return lifeWiseIndividualFooterLocalContent as FooterContent;

    if (ListeningPostSettings.brandingType === Constants.brandTypeLifeWiseOfWashington)
      return lifeWiseWashingtonFooterLocalContent as FooterContent;

    if (ListeningPostSettings.brandingType === Constants.brandTypePremeraMedicareAdvantage)
      return premeraMedicareAdvantageLocalContent as FooterContent;

    return premerafooterLocalContent as FooterContent;
  }

}
