import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvalidRouteComponent } from './error/invalid-route.component';
import { HomeComponent } from './home/home.component';
import { ThankYouComponent } from './thankYou/thankYou.component';
import { Constants } from "./shared/constants";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: Constants.routeHome, component: HomeComponent },
  { path: Constants.routeHome.toLowerCase(), component: HomeComponent },
  { path: Constants.routeHome.toUpperCase(), component: HomeComponent },
  { path: Constants.routeHmo, component: HomeComponent },
  { path: Constants.routeHmo.toLowerCase(), component: HomeComponent },
  { path: Constants.routeHmo.toUpperCase(), component: HomeComponent },
  { path: Constants.routeMemberCommunications.toLowerCase(), component: HomeComponent },
  { path: Constants.routeMemberCommunications.toUpperCase(), component: HomeComponent },
  { path: Constants.routeMemberCommunications, component: HomeComponent },
  { path: Constants.routeProvider, component: HomeComponent },
  { path: Constants.routeProvider.toLowerCase(), component: HomeComponent },
  { path: Constants.routeProvider.toUpperCase(), component: HomeComponent },
  { path: Constants.routeStudentInsurance, component: HomeComponent },
  { path: Constants.routeStudentInsurance.toLowerCase(), component: HomeComponent },
  { path: Constants.routeStudentInsurance.toUpperCase(), component: HomeComponent },
  { path: Constants.routeLifewiseGroup, component: HomeComponent },
  { path: Constants.routeLifewiseGroup.toLowerCase(), component: HomeComponent },
  { path: Constants.routeLifewiseGroup.toUpperCase(), component: HomeComponent },
  { path: Constants.routeMedicareAdvantage, component: HomeComponent },
  { path: Constants.routeMedicareAdvantage.toLowerCase(), component: HomeComponent },
  { path: Constants.routeMedicareAdvantage.toUpperCase(), component: HomeComponent },
  { path: Constants.routeThankYou, component: ThankYouComponent },
  { path: Constants.routeThankYou.toLowerCase(), component: ThankYouComponent },
  { path: Constants.routeThankYou.toUpperCase(), component: ThankYouComponent },
  { path: Constants.routeThankYouHmo, component: ThankYouComponent },
  { path: Constants.routeThankYouHmo.toLowerCase(), component: ThankYouComponent },
  { path: Constants.routeThankYouHmo.toUpperCase(), component: ThankYouComponent },
  { path: Constants.routeInvalidRoute, component: InvalidRouteComponent },
  { path: Constants.routeInvalidRoute.toLowerCase(), component: InvalidRouteComponent },
  { path: Constants.routeInvalidRoute.toUpperCase(), component: InvalidRouteComponent },
  { path: Constants.routeProviderLifewiseGroup, component: HomeComponent },
  { path: Constants.routeProviderLifewiseGroup.toLowerCase(), component: HomeComponent },
  { path: Constants.routeProviderLifewiseGroup.toUpperCase(), component: HomeComponent },
  { path: Constants.routeProviderStudentInsurance, component: HomeComponent },
  { path: Constants.routeProviderStudentInsurance.toLowerCase(), component: HomeComponent },
  { path: Constants.routeProviderStudentInsurance.toUpperCase(), component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
