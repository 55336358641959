import { Component, OnInit } from '@angular/core';
import { Constants } from "../shared/constants";
import { ListeningPostSettings } from '../listening-post-settings';

@Component({
  selector: 'pl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  lob: string;
  logo: string;
  homeUrl: string;

  ngOnInit() {
    this.lob = ListeningPostSettings.lineOfBusiness;
    this.logo = ListeningPostSettings.logoImagePath;
    this.homeUrl = this.getHomeUrl();
  }

  private getHomeUrl(): string {
    let url = location.origin;
    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPost
      || ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostIndividual)
      return url;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostMobile) {
      if (ListeningPostSettings.brandingType == Constants.brandTypePremeraHMO)
        url += `/${Constants.routeHmo}`;
      if (ListeningPostSettings.brandingType == Constants.brandTypePremeraMedicareAdvantage)
        url += `/${Constants.routeMedicareAdvantage}`;
      return url;
    }

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostHmo)
      return url += `/${Constants.routeHmo}`;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostLifewiseGroup)
      return url += `/${Constants.routeLifewiseGroup}`;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostStudentInsurance)
      return url += `/${Constants.routeStudentInsurance}`;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostMedicareAdvantage)
      return url += `/${Constants.routeMedicareAdvantage}`;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostMemberCommunications)
      return url += `/${Constants.routeMemberCommunications}`;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProvider)
      return url += `/${Constants.routeProvider}`;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProviderGroup)
      return url += `/${Constants.routeProviderLifewiseGroup}`;

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProviderStudent)
      return url += `/${Constants.routeProviderStudentInsurance}`;

    return url;
  }

}
